.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 200px);
  column-gap: 20px;
  row-gap: 50px;
  justify-items: center;
}

.customqr_wrap {
  width: 144px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  padding: 20px;
  position: relative;
}

.customqr_wrap img {
  width: 144px;
  position: absolute;
  /* z-index: -1; */
}

.customqr-id {
  position: absolute;
  right: 13px;
  top: 70px;
  font-weight: 400;
  color: #7b7b7b;
  font-size: 6px;

  transform: rotate(90deg);
}

/* .customqr_logo {
  position: absolute;
  z-index: 100;
  width: 100px;
  height: 100px;
}

.customqr_logo img {
  width: 100%;
  height: 100%;
} */
