.table__wrap {
  width: 100%;
  height: 100%;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.table__wrap::-webkit-scrollbar {
  width: 6px !important;
}

.table__wrap::-webkit-scrollbar-thumb {
  background-color: #5773ff;
  border-radius: 10px;
}

/* table header styling */

.table__header-action {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.table__header-action form {
  width: 45%;
}

.table__form-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 13px;
  border: 2px solid lightgrey;
  border-radius: 10px;
}

.table__form-box input {
  width: 100%;
  outline: none;
  font-family: inherit;
  font-size: 15px;
  border: none;
  margin-left: 10px;
}

.table__header-icons {
  width: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table__header-icons {
  margin-right: 10px;
}

.react-daterange-picker__calendar {
  z-index: 9999 !important;
  position: relative;
}
.table__date-range {
  width: 45%;
  display: flex;
  justify-content: space-between;
}

/* table styling */
.table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 10px;
  overflow-y: scroll;
  z-index: -1;
}

.table__body {
  width: 90%;
}

.table th {
  text-align: left;
  font-size: 17px !important;
  font-weight: bold;
}
.table th,
td {
  padding: 15px;
  font-family: inherit !important;
}

.table .notseen {
  font-family: "Mont-700";
}

.table td {
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.table tbody {
  overflow-y: scroll;
}

.table th {
  background-color: #5773ff;
  color: #fff;
}

.top__icon {
  position: absolute;
  bottom: 20px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #5773ff;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.hide {
  display: none;
}
