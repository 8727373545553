@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

.container {
  width: 100%;
  height: 100%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 390px !important;
  font-family: inherit !important;
}

.MuiTableContainer-root {
  height: 80%;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  font-family: "Poppins" !important;
  text-transform: none !important;
}
